const checkEmail = (rule, value, callback) => {
  const mailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (mailReg.test(value)) {
    callback();
  } else {
    callback(new Error('正しいメールアドレスを入力ください。'));
  }
};

const checkPassword = (rule, value, callback) => {
  const letterReg = /[a-zA-Z]/;
  const numberReg = /[0-9]/;

  if (letterReg.test(value) && numberReg.test(value) && value.length > 5) {
    callback();
  } else {
    callback(new Error('パスワードは英字と数字をどちらも含む6文字以上の半角英数記号でご設定ください。'));
  }
};

const checkUserName = (rule, value, callback) => {
  const letterReg = /[a-zA-Z]/;
  const numberReg = /[0-9]/;

  if (letterReg.test(value) && numberReg.test(value) && value.length > 5) {
    callback();
  } else {
    callback(new Error('ログインIDは半角英数字を入力してください。'));
  }
};

const shouldShowDetailList = (status) => ['1', '2', '3'].indexOf(status) > -1;

/**
 * 郵便番号
 * @param {*} s
 */
 export function isZipCode (s) {
  return /^[0-9]{3}[0-9]{4}$/.test(s)
}

/**
 * kana
 * @param {*} s
 */
export function isKana (s) {
  return /^[\u30A0-\u30FF]+$/.test(s)
}

/**
 * 日本手机号码
 * @param {*} s
 */
export function isMobile (s) {
  // return /^1[0-9]{10}$/.test(s)
  return /0[789]0-?[0-9]{4}-?[0-9]{4}$/.test(s)
}

/**
 * 日本固定电话
 * @param {*} s
 */
export function isFixedPhone (s) {
  return /^0[0-9]{9}$/.test(s)
}

/**
 * 日本传真
 * @param {*} s
 */
export function isFax (s) {
  return /^0[0-9]{9}$/.test(s)
}


export default {
  checkEmail,
  checkPassword,
  checkUserName,
  shouldShowDetailList,
};
