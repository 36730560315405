const mapSource = [
  {
    id: 3844,
    text: '北海道',
  }, {
    id: 3846,
    text: '青森县',
  }, {
    id: 3847,
    text: '岩手县',
  }, {
    id: 3848,
    text: '宫城县',
  }, {
    id: 3849,
    text: '秋田县',
  }, {
    id: 3850,
    text: '山形县',
  }, {
    id: 3851,
    text: '福岛县',
  }, {
    id: 3853,
    text: '茨城县',
  }, {
    id: 3854,
    text: '栃木县',
  }, {
    id: 3855,
    text: '群马县',
  }, {
    id: 3856,
    text: '埼玉县',
  }, {
    id: 3857,
    text: '千叶县',
  }, {
    id: 3858,
    text: '东京都',
  }, {
    id: 3859,
    text: '神奈川县',
  }, {
    id: 3861,
    text: '新潟县',
  }, {
    id: 3862,
    text: '富山县',
  }, {
    id: 3863,
    text: '石川县',
  }, {
    id: 3864,
    text: '福井县',
  }, {
    id: 3865,
    text: '山梨县',
  }, {
    id: 3866,
    text: '长野县',
  }, {
    id: 3867,
    text: '岐阜县',
  }, {
    id: 3868,
    text: '静冈县',
  }, {
    id: 3869,
    text: '爱知县',
  }, {
    id: 3871,
    text: '滋贺县',
  }, {
    id: 3872,
    text: '京都府',
  }, {
    id: 3873,
    text: '大阪府',
  }, {
    id: 3874,
    text: '兵库县',
  }, {
    id: 3875,
    text: '奈良县',
  }, {
    id: 3876,
    text: '和歌山县',
  }, {
    id: 3898,
    text: '三重县',
  }, {
    id: 3878,
    text: '鸟取县',
  }, {
    id: 3879,
    text: '岛根县',
  }, {
    id: 3880,
    text: '冈山县',
  }, {
    id: 3881,
    text: '广岛县',
  }, {
    id: 3882,
    text: '山口县',
  }, {
    id: 3884,
    text: '德岛县',
  }, {
    id: 3885,
    text: '香川县',
  }, {
    id: 3886,
    text: '爱媛县',
  }, {
    id: 3887,
    text: '高知县',
  }, {
    id: 3889,
    text: '福冈县',
  }, {
    id: 3890,
    text: '佐贺县',
  }, {
    id: 3891,
    text: '长崎县',
  }, {
    id: 3892,
    text: '熊本县',
  }, {
    id: 3893,
    text: '大分县',
  }, {
    id: 3894,
    text: '宫崎县',
  }, {
    id: 3895,
    text: '鹿儿岛县',
  }, {
    id: 3897,
    text: '冲绳县',
  },
];

const myCourseStatusOptions = [
  {
    value: '0',
    label: '申請中',
  },
  {
    value: '1',
    label: '申請承認済',
  },
  {
    value: '2',
    label: '受験中',
  },
  {
    value: '3',
    label: '受験終了',
  },
  {
    value: '4',
    label: '申請拒否',
  },
];

export default {
  mapSource,
  myCourseStatusOptions,
};
