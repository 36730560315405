<template>
  <div class="profile">
    <div class="profile-title">設定-本人情報</div>
    <el-form ref="profileForm" :model="form" class="app-form" :rules="rules">
      <el-form-item label="ログインID" prop="userName">
        <el-input v-model="form.userName" autocomplete="off" :disabled="true"
                  placeholder="ログインID"
        ></el-input>
      </el-form-item>
      <el-form-item label="メールアドレス" prop="email">
        <el-input v-model="form.email" autocomplete="off" :disabled="true"
                  placeholder="メールアドレス"
        ></el-input>　登録メールアドレスを変更したい場合は、お問い合わせよりご連絡ください。
      </el-form-item>
      <el-form-item label="名前（漢字）" prop="userNameKanji">
        <el-input v-model="form.userNameKanji" autocomplete="off"
          placeholder="名前 漢字"
        ></el-input>
      </el-form-item>
      <el-form-item label="名前（カナ）" prop="userNameKana">
        <el-input v-model="form.userNameKana" autocomplete="off"
          placeholder="ナマエ カナ"
        ></el-input>
      </el-form-item>
      <el-form-item label="ニックネーム" prop="nickName">
        <el-input v-model="form.nickName" autocomplete="off"
          placeholder="ニックネーム"
        ></el-input>
      </el-form-item>
      <el-form-item label="生年月日" prop="birthday">
        <el-date-picker
          v-model="form.birthday"
          type="date"
          placeholder="生年月日"
          format="yyyy/MM/dd"
          :picker-options="{
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        }">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="性別" prop="sex">
        <el-radio v-model="form.sex" label="0">男性</el-radio>
        <el-radio v-model="form.sex" label="1">女性</el-radio>
      </el-form-item>
      <!-- <el-form-item label="パスワード" prop="password">
        <el-input type="password" v-model="form.password" autocomplete="off"
          placeholder="パスワード"
        ></el-input>
      </el-form-item>
      <el-form-item label="パスワード（再入力）" prop="passwordConfirm">
        <el-input type="password" v-model="form.passwordConfirm" autocomplete="off"
          placeholder="パスワード（再入力）"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="郵便（〒）" prop="addressZipCode">
        <el-input v-model="form.addressZipCode" autocomplete="off" @blur="zipSelect"
          placeholder="郵便（〒）"
        ></el-input>
      </el-form-item>
      <el-form-item label="住所：県" prop="addressPrefecture">
        <el-input v-model="form.addressPrefecture" placeholder="住所：県" disabled></el-input>
      </el-form-item>
      <el-form-item label="住所：市区町村" prop="addressCity">
        <el-input v-model="form.addressCity" autocomplete="off" :disabled="true"
          placeholder="住所：市区町村"
        ></el-input>
      </el-form-item>
      <el-form-item label="住所：番地以下" prop="addressOthers">
        <el-input v-model="form.addressOthers" autocomplete="off"
          placeholder="住所：番地以下"
        ></el-input>
      </el-form-item>
      <el-form-item label="住所：建物名" prop="addressBuildingName">
        <el-input v-model="form.addressBuildingName" autocomplete="off"
          placeholder="住所：建物名"
        ></el-input>
      </el-form-item>
      <el-form-item label="連絡先：携帯番号" prop="contactMobile">
        <el-input v-model="form.contactMobile" autocomplete="off"
          placeholder="連絡先：携帯(例：08012345678)"
        ></el-input>
      </el-form-item>
      <el-form-item label="連絡先：電話番号" prop="contactTel">
        <el-input v-model="form.contactTel" autocomplete="off" 
          placeholder="連絡先：電話(例：0123456789)"
        ></el-input>
      </el-form-item>
      <el-form-item label="連絡先：FAX番号" prop="contactFax">
        <el-input v-model="form.contactFax" autocomplete="off"
          placeholder="連絡先：FAX(例：0123456789)"
        ></el-input>
      </el-form-item>
      <div class="profile-docs">
      「<a href = "/terms.html" target="_blank">利用規約</a>」
      「<a href = "https://www.tkhs.co.jp/about/privacy.html" target="_blank">個人情報の取り扱いについて</a>」
        <span>をお読みになり同意いただける場合にご登録ください。</span>
      </div>
      <el-form-item>
        <el-button class="studypack-button-primary" type="primary" @click="onSubmit">変更</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import constant from '@/utils/constant';
import tools from '@/utils/tools';
import request from '@/utils/request';
import moment from 'moment'
import { Notification } from 'element-ui';
import { isMobile, isKana, isFixedPhone, isFax, isZipCode } from '@/utils/tools'

const { mapSource } = constant;
const { checkEmail } = tools;

export default {
  name: 'Profile',
  data() {
    var validateMobile = (rule, value, callback) => {
      if (!isMobile(value) && value !== null && value !== '') {
        callback(new Error('連絡・携帯番号フォーマットエラー'))
      } else {
        callback()
      }
    }
    var validateKana = (rule, value, callback) => {
      if (!isKana(value) && value !== null && value !== '') {
        callback(new Error('全角カタカナで入力してください'))
      } else {
        callback()
      }
    }
    var validateFixedPhone = (rule, value, callback) => {
      if (!isFixedPhone(value) && value !== null && value !== '') {
        callback(new Error('連絡・電話番号フォーマットエラー'))
      } else {
        callback()
      }
    }
    var validateFax = (rule, value, callback) => {
      if (!isFax(value) && value !== null && value !== '') {
        callback(new Error('連絡・FAX番号フォーマットエラー'))
      } else {
        callback()
      }
    }
    var validateZipCode = (rule, value, callback) => {
      if (!isZipCode(value) && value !== null && value !== '') {
        callback(new Error('ハイフンなし、数字7桁で入力してください'))
      } else {
        callback()
      }
    }
    return {
      mapSource,
      form: {
        email: '',
        userName: '',
        userNameKanji: '',
        userNameKana: '',
        nickName: '',
        birthday: '',
        sex: '',
        addressZipCode: '',
        addressPrefecture: '',
        addressCity: '',
        addressOthers: '',
        addressBuildingName: '',
        contactMobile: '',
        contactTel: '',
        contactFax: '',
      },
      rules: {
        email: [
          { required: true, message: '必須です。', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' },
        ],
        userNameKanji: [
          // { required: true, message: '必須です。', trigger: 'blur' },
          { max: 50, message: '50文字以内を入力ください。', trigger: 'blur' },
        ],
        userNameKana: [
          // { required: true, message: '必須です。', trigger: 'blur' },
          { max: 50, message: '50文字以内を入力ください。', trigger: 'blur' },
          { validator: validateKana, trigger: 'blur' }
        ],
        nickName: [
          // { required: true, message: '必須です。', trigger: 'blur' },
          { max: 50, message: '50文字以内を入力ください。', trigger: 'blur' },
        ],
        // birthday: [
        //   {
        //     type: 'date', required: false, trigger: 'blur',
        //   },
        // ],
        sex: [
          // { required: true, message: '必須です。', trigger: 'blur' },
        ],
        addressZipCode: [
          // { required: true, message: '必須です。', trigger: 'blur' },
            { validator: validateZipCode, trigger: 'blur' }
        ],
        // addressPrefecture: [
        //   { required: true, message: '必須です。', trigger: 'blur' },
        // ],
        // addressOthers: [
        //   { required: true, message: '必須です。', trigger: 'blur' },
        // ],
        // addressArea: [
        //   { required: true, message: '必須です。', trigger: 'blur' },
        // ],
        contactMobile: [
          // { required: true, message: '必須です。', trigger: 'blur' },
          { validator: validateMobile, trigger: 'blur' }
        ],
        contactTel: [
          // { required: true, message: '必須です。', trigger: 'blur' },
          { validator: validateFixedPhone, trigger: 'blur' }
        ],
        contactFax: [
          // { required: true, message: '必須です。', trigger: 'blur' },
          { validator: validateFax, trigger: 'blur' }
        ],
      },
    };
  },
  created() {
    request('getMyProfile').then((res) => {
      this.form = {
        email: res.email,
        userName: res.userName,
        userNameKanji: res.userNameKanji,
        userNameKana: res.userNameKana,
        nickName: res.nickName,
        birthday: res.birthday,
        sex: res.sex,
        addressZipCode: res.addressZipCode,
        addressPrefecture: res.addressPrefecture,
        addressCity: res.addressCity,
        addressOthers: res.addressOthers,
        addressBuildingName: res.addressBuildingName,
        contactMobile: res.contactMobile,
        contactTel: res.contactTel,
        contactFax: res.contactFax,
      };
      if (res.birthday !== '' && res.birthday !== null) {
        this.form.birthday = moment(res.birthday, 'YYYYMMDD')
      }
    });
  },
  methods: {
    zipSelect () {
      this.$refs.profileForm.validateField('addressZipCode',(valid) => {
        if (valid === '') {
          if (this.form.addressZipCode !== '' && this.form.addressZipCode !== null) {
            request('getMyZipInfo', {
                zip: this.form.addressZipCode
              }).then((res) => {
                  this.form.addressPrefecture = res.kenName
                  this.form.addressCity = res.cityName + res.townName
                console.log(res)
              });
          }
          return true;
        }
        return false;
      });
    },
    onSubmit() {
      this.$refs.profileForm.validate((valid) => {
        if (valid) {
          if (this.form.birthday !== '' && this.form.birthday !== null) {
            this.form.birthday = moment(this.form.birthday).format('YYYYMMDD')
          }
          request('setMyProfile', this.form).then((res) => {
            Notification({
              title: '成功',
              message: '本人情報の変更が成功しました。',
              // duration: 0,
              offset: 75,
              type: 'success',
            });
            request('getMyProfile').then((res) => {
              this.form = {
                email: res.email,
                userName: res.userName,
                userNameKanji: res.userNameKanji,
                userNameKana: res.userNameKana,
                nickName: res.nickName,
                birthday: res.birthday,
                sex: res.sex,
                addressZipCode: res.addressZipCode,
                addressPrefecture: res.addressPrefecture,
                addressCity: res.addressCity,
                addressOthers: res.addressOthers,
                addressBuildingName: res.addressBuildingName,
                contactMobile: res.contactMobile,
                contactTel: res.contactTel,
                contactFax: res.contactFax,
              };
              if (res.birthday !== '' && res.birthday !== null) {
                this.form.birthday = moment(res.birthday, 'YYYYMMDD')
              }
            });
          });
          return true;
        }
        console.log('error submit!!');
        return false;
      });
    },
  },
};
</script>

<style lang="less">
.profile {
  margin: 20px auto;
  width: 600px;

  .profile-title {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .profile-subtitle {
    text-align: center;
    font-size: 18px;
    margin-bottom: 24px;
  }
  .profile-docs {
    font-size: 16px;
    margin-bottom: 24px;
    line-height: 1.2;
  }
}
</style>
